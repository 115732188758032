import type { SVGProps } from 'react';

export function Info({
  width = 32,
  height = 32,
  role = 'presentation',
  color,
  ...attributes
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      fill='none'
      height={height}
      role={role}
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      {...attributes}
    >
      <g clipPath='url(#clip0_23221_31795)'>
        <path
          clipRule='evenodd'
          d='M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30ZM16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z'
          fill={color ?? 'white'}
          fillRule='evenodd'
        />
        <path
          d='M14.4906 8.95C14.4906 8.83954 14.5801 8.75 14.6906 8.75H17.2877C17.3981 8.75 17.4877 8.83954 17.4877 8.95V10.8521C17.4877 10.9626 17.3981 11.0521 17.2877 11.0521H14.6906C14.5801 11.0521 14.4906 10.9626 14.4906 10.8521V8.95ZM13.1875 21.123C13.1875 21.0126 13.277 20.923 13.3875 20.923H13.7522C14.0562 20.923 14.2734 20.8579 14.4037 20.7276C14.534 20.5828 14.5992 20.3656 14.5992 20.076V15.4175C14.5992 15.0989 14.5413 14.8673 14.4254 14.7225C14.3241 14.5777 14.1141 14.5053 13.7956 14.5053H13.3875C13.277 14.5053 13.1875 14.4158 13.1875 14.3053V12.8158C13.1875 12.7054 13.277 12.6158 13.3875 12.6158H17.2443C17.3547 12.6158 17.4443 12.7054 17.4443 12.8158V20.1194C17.4443 20.3945 17.5022 20.5972 17.618 20.7276C17.7483 20.8579 17.9583 20.923 18.2478 20.923H18.6125C18.723 20.923 18.8125 21.0126 18.8125 21.123V22.6125C18.8125 22.723 18.723 22.8125 18.6125 22.8125H13.3875C13.277 22.8125 13.1875 22.723 13.1875 22.6125V21.123Z'
          fill={color ?? 'white'}
        />
      </g>
      <defs>
        <clipPath id='clip0_23221_31795'>
          <rect fill={color ?? 'white'} height={height} width={width} />
        </clipPath>
      </defs>
    </svg>
  );
}
