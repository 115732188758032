import { env } from 'env';

export const featureFlagNames = {
  SITE_BANNER: 'join-flow-site-banner',
  FLIPKART_REDIRECT: 'redirect-india-flipkart',
  USE_INR_RENEWAL_PRICE: 'jf-inr-renewal-price',
  CLARIFIED_GIFT_FLOW: 'clarified-gift-flow',
  DISABLE_IL_JF: 'il-disabled-on-JF',
  NEW_ROUTES: 'growth-jf-new-routes',
  SKIP_GIFT_SELECT: 'jf-skip-gift-select',
  CYBER_TRAFFIC: 'cyber-send-traffic-to-ecom-jf',
  CR7_PROMO: 'jf-cr7-promo',
  ANNUAL_GIFT_CARD: 'jf-annual-gift-card',
  ADDRESS_VALIDATION: 'be-address-validation-enabled',
  ADDRESS_SMARTY: 'be-address-smarty-enabled',
  SHIPPING_DELAY_WARNING: 'growth-jf-shipping-delay-warning',
  NO_EXPEDITED_SHIPPING_TRIALS: 'jf-no-expedited-shipping-trials',
};

export const getAreFeatureFlagsOnForAll = async (
  flags: string[],
): Promise<Record<string, boolean>> => {
  const promises = flags.map(async (flagName: string) => {
    try {
      const response = await fetch(
        `${env.NEXT_PUBLIC_WHOOP_API}/feature-flags/flags/${flagName}/onforall`,
        {
          method: 'GET',
          headers: {
            // Fetch needs an accept language, otherwise it returns a 500
            // https://github.com/denoland/deno/issues/10987
            'Accept-Language': 'en',
          },
          next: { revalidate: 0 },
        },
      );
      if (response.ok) {
        return response.json();
      }
      return false;
    } catch (_err) {
      // False is the default FF state if anything fails
      return false;
    }
  });

  const results = await Promise.allSettled(promises);
  const parsedFlags: Record<string, boolean> = {};
  results.forEach((result, index) => {
    if (result.status === 'fulfilled') {
      parsedFlags[flags[index]] = result.value;
    } else {
      parsedFlags[flags[index]] = false;
    }
  });

  return parsedFlags;
};
