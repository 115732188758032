import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cltw } from '../../utils';

/**
 * @documentation https://cva.style/docs
 */
const buttonVariants = cva(
  'relative flex w-full items-center justify-center overflow-hidden rounded-full border-2 uppercase leading-4 tracking-widest',
  {
    variants: {
      variant: {
        default:
          'font-bold border-black bg-white text-black hover:bg-gray-200 dark:border-white disabled:border-gray-10 disabled:bg-gray-10 disabled:text-inactive text-base',
        primary:
          'font-bold border-black bg-white text-black hover:bg-gray-200 dark:border-white disabled:border-gray-10 disabled:bg-gray-10 disabled:text-inactive',
        secondary:
          'font-bold border-black bg-black text-white hover:bg-gray-900 dark:border-white disabled:border-gray-400 disabled:bg-gray-500 disabled:text-gray-400',
        secondaryNoHover:
          'border-black bg-black text-white dark:border-white disabled:border-gray-400 disabled:bg-gray-500 disabled:text-gray-400',
        activePill:
          'border border-gray-300 bg-black text-white dark:border-white disabled:border-gray-400 disabled:bg-gray-500 disabled:text-gray-400 rounded-xl px-4 py-2 normal-case text-base font-normal',
        tertiary:
          'font-bold border-black text-black hover:bg-gray-200 disabled:border-gray-500 disabled:text-gray-500 disabled:bg-white',
        tertiaryBright:
          'font-bold border-white text-white hover:cursor-pointer disabled:bg-white disabled:text-gray-500 disabled:border-transparent disabled:bg-opacity-10',
        bright:
          'font-bold border-accent bg-accent text-white hover:bg-accent-hover disabled:border-gray-400 disabled:bg-gray-500 disabled:text-gray-400',
        inactivePill:
          'border border-gray-500 bg-white text-gray-500 rounded-xl hover:bg-accent-hover hover:bg-gray-100 normal-case text-base font-normal px-4 py-2',
        secondaryBright:
          'font-bold border-black bg-white text-black hover:bg-accent hover:border-white hover:text-white',
        brightNotBold:
          'border-accent bg-accent text-white hover:bg-accent-hover disabled:border-gray-10 disabled:bg-transparent disabled:text-inactive',
        red: 'border-red-a400 bg-red-a400 text-white hover:bg-red-200 disabled:border-gray-400 disabled:bg-gray-500 disabled:text-gray-400 font-semibold',
        transparent:
          'font-bold border-transparent bg-transparent hover:bg-gray-200 disabled:text-gray-500',
        gradient:
          'font-bold bg-gradient-to-r from-blue-700 to-blue-500 text-white hover:border-black',
        underline:
          'font-bold underline decoration-2 underline-offset-2 border-0 disabled:text-gray-500',
      },
      size: {
        default: 'p-3.5 text-sm max-h-12',
        small: 'p-2 text-xs max-h-9',
        smMed: 'p-2.5 text-sm max-h-10',
        medium: 'p-3.5 text-sm max-h-12',
        large: 'p-5 text-lg max-h-14',
        icon: 'h-9 w-9',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

/**
 * @description Shadcn Button
 * @documentation https://ui.shadcn.com/docs/components/button
 * @see cva: https://cva.style/docs
 * @see Radix Composition: https://www.radix-ui.com/primitives/docs/guides/composition
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cltw(buttonVariants({ variant, size }), className)}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = 'Button';

const UnstyledButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return <Comp className={cltw(className)} ref={ref} {...props} />;
  },
);
UnstyledButton.displayName = 'Unstyled Button';
export { buttonVariants, Button, UnstyledButton };
