import dynamic from 'next/dynamic';
import { useMemo } from 'react';
import {
  iconPathMappings,
  type IconProps,
  type IconTypeVariants,
} from './Icon.util';

export type IconSizes = 'small' | 'medium' | 'large' | 'xl' | 'rectangular';

export const getIconSizeClassName = (size: IconSizes): string => {
  switch (size) {
    case 'small':
      return 'max-w-[16px] h-[16px] w-[16px]';
    case 'medium':
      return 'max-w-[32px] h-[32px] w-[32px]';
    case 'large':
      return 'max-w-[48px] h-[48px] w-[48px]';
    case 'xl':
      return 'max-w-[64px] h-[64px] w-[64px]';
    case 'rectangular':
      return 'max-w-[34px] h-[24px] w-[34px]';
  }
};

const getIconHeight = (size: IconSizes): number => {
  switch (size) {
    case 'small':
      return 16;
    case 'medium':
      return 32;
    case 'large':
      return 48;
    case 'xl':
      return 64;
    case 'rectangular':
      return 23;
  }
};

const getIconWidth = (size: IconSizes): number => {
  switch (size) {
    case 'small':
      return 16;
    case 'medium':
      return 32;
    case 'large':
      return 48;
    case 'xl':
      return 64;
    case 'rectangular':
      return 33;
  }
};

const fallbackComponent = (size: IconSizes): JSX.Element => {
  const sizeClassName = getIconSizeClassName(size);
  return (
    <div
      className={`${sizeClassName} bg-gray-20 flex-1 animate-pulse rounded-full`}
    />
  );
};

export interface IconComponentProps {
  variant: IconTypeVariants;
  alt: string;
  size?: IconSizes;
  color?: string;
}

export function Icon({
  variant,
  alt,
  size = 'medium',
  color = '#000000ff',
}: IconComponentProps): JSX.Element {
  const iconPath = iconPathMappings[variant];
  // note(1/2): we need to know the name of the exported stuff to ger it on the dynamic import line
  const splittedIconPath = iconPath?.split?.('/');
  const iconComponentName = splittedIconPath?.[splittedIconPath.length - 1];

  // memoize the Icon component dynamic import to avoid re-rendering
  const Icon = useMemo(() => {
    return dynamic<IconProps>(
      () =>
        import(`../../icons/icons/${iconPath}.tsx`)
          // note(2/2): here we take the name of the icon component from the imported module
          .then((ImportedModule) => ImportedModule[iconComponentName])
          .catch((err) => {
            console.error(`Failed to import icon: ${variant}`, err);
          }),
      {
        loading: () => fallbackComponent(size),
      },
    );
  }, [iconPath, variant, size]);

  // if the iconPath is not found, log an error and return a fallback component
  if (!iconPath) {
    console.error(`Unknown icon variant: ${variant}`);
    return fallbackComponent(size);
  }

  return (
    <Icon
      fill={color}
      height={getIconHeight(size)}
      title={alt}
      width={getIconWidth(size)}
    />
  );
}
