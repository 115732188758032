// These constants are used in production code and should not be changed for tests

export const ENGRAVING_SKU = '940-000010-010';
export const ENGRAVING_KEY = 'engraving';
export const GEN_4_DEVICE_SKU = '970-001-000';

export const GEN_4_BATTERY_PACK = 'gen4-black-box-bp';
export const GEN_4_DEVICE_KEY = 'gen4-black-box';
export const WYW_SKU = 'wyw-sku';
export const ONYX_SKU = '955-01-000-000008';
