export interface IconProps {
  title?: string;
  width?: number;
  height?: number;
  fill?: string;
  role?: string;
  stroke?: string;
  strokeWidth?: number;
  className?: string;
}

export const iconPathMappings = {
  sign_up: 'Referral/SignUp',
  succeed_red: 'Referral/SucceedRed',
  refer_a_friend: 'Referral/ReferAFriend',
  succeed_gray: 'Referral/SucceedGray',
  tonnage: 'Strain/Tonnage',
  list: 'Strain/List',
  training_adaptation: 'Strain/TrainingAdaptation',
  activity_detection: 'Strain/ActivityDetection',
  heart_rate: 'Strain/HeartRate',
  activity_settings: 'Strain/ActivitySettings',
  strain: 'Strain/Strain',
  break: 'Strain/Break',
  superset: 'Strain/Superset',
  calories: 'Strain/Calories',
  data_catch_up: 'Connection/DataCatchUp',
  bluetooth: 'Connection/Bluetooth',
  no_internet: 'Connection/NoInternet',
  integrations: 'Connection/Integrations',
  phone: 'Connection/Phone',
  charging: 'Connection/Charging',
  cloud: 'Connection/Cloud',
  hours_of_sleep: 'Sleep/HoursOfSleep',
  bedtime: 'Sleep/Bedtime',
  sleep: 'Activities/Sleep',
  disturbances: 'Sleep/Disturbances',
  waketime: 'Sleep/Waketime',
  latency: 'Sleep/Latency',
  nap: 'Activities/Nap',
  duration: 'Sleep/Duration',
  efficiency: 'Sleep/Efficiency',
  sleep_need: 'Sleep/SleepNeed',
  sleep_debt: 'Sleep/SleepDebt',
  hrv_max: 'Recovery/HrvMax',
  recovery: 'Recovery/Recovery',
  breathwork: 'Recovery/Breathwork',
  nsdr: 'Recovery/Nsdr',
  hrv_outline: 'ProductHighlights/HrvOutline',
  hrv_min: 'Recovery/HrvMin',
  max_heart_rate: 'Recovery/MaxHeartRate',
  blood_oxygen: 'Recovery/BloodOxygen',
  skin_temperature: 'Recovery/SkinTemperature',
  rhr: 'Recovery/Rhr',
  menstrual_cycle: 'Recovery/MenstrualCycle',
  hrv_fill: 'Recovery/HrvFill',
  respiratory_rate: 'Recovery/RespiratoryRate',
  score_hidden: 'Recovery/ScoreHidden',
  pay_pal: 'Payment/PayPal',
  visa: 'Payment/Visa',
  apple_pay: 'Payment/ApplePay',
  google_pay: 'Payment/GooglePay',
  mastercard: 'Payment/Mastercard',
  amex: 'Payment/Amex',
  diners_club: 'Payment/DinersClub',
  shop_pay: 'Payment/ShopPay',
  discover: 'Payment/Discover',
  weight: 'Health/Weight',
  whoop_live: 'Navigation/WhoopLive',
  locked_pa: 'Navigation/LockedPa',
  profile: 'Navigation/Profile',
  weekly_pa: 'Navigation/WeeklyPa',
  pause: 'Navigation/Pause',
  stop: 'Navigation/Stop',
  monthly_pa: 'Navigation/MonthlyPa',
  home: 'Navigation/Home',
  camera: 'Navigation/Camera',
  camera_ratio: 'Navigation/CameraRatio',
  caret_right: 'Navigation/CaretRight',
  sleep_schedule: 'Navigation/SleepSchedule',
  adv_settings: 'Navigation/AdvSettings',
  coach: 'Navigation/Coach',
  arrow_left: 'Navigation/ArrowLeft',
  caret_left: 'Navigation/CaretLeft',
  caret_up: 'Navigation/CaretUp',
  arrow_right: 'Navigation/ArrowRight',
  zoom_out: 'Navigation/ZoomOut',
  community: 'Navigation/Community',
  undo: 'Navigation/Undo',
  caret_down: 'Navigation/CaretDown',
  menu: 'Navigation/Menu',
  strain_coach: 'Navigation/StrainCoach',
  annual_pa: 'Navigation/AnnualPa',
  play: 'Navigation/Play',
  reply: 'Navigation/Reply',
  dot_menu: 'Navigation/DotMenu',
  live: 'Navigation/Live',
  settings: 'Navigation/Settings',
  sleep_coach: 'Navigation/SleepCoach',
  stop_activity: 'Navigation/StopActivity',
  zoom_in: 'Navigation/ZoomIn',
  credit_card: 'Growth/CreditCard',
  new_arrivals: 'Growth/NewArrivals',
  mxm_support: 'Growth/MxmSupport',
  shipping: 'Growth/Shipping',
  discount: 'Growth/Discount',
  key: 'Growth/Key',
  gift_card: 'Growth/GiftCard',
  partnership: 'Growth/Partnership',
  shopping_cart: 'Growth/ShoppingCart',
  chat: 'Growth/Chat',
  gift: 'Growth/Gift',
  ownership: 'Growth/Ownership',
  membership_extend: 'Growth/MembershipExtend',
  thumb_down_filled: 'Various/ThumbDownFilled',
  return_arrow: 'Various/ReturnArrow',
  leave_team: 'Various/LeaveTeam',
  thumb_down: 'Various/ThumbDown',
  logo_pro: 'Various/LogoPro',
  gesture_pinch: 'Various/GesturePinch',
  advice: 'Various/Advice',
  notes_edit: 'Various/NotesEdit',
  export: 'Various/Export',
  feel_better: 'Various/FeelBetter',
  my_account: 'Various/MyAccount',
  thumb_up_filled: 'Various/ThumbUpFilled',
  drag: 'Various/Drag',
  help_center: 'Various/HelpCenter',
  unisex_symbol: 'Various/UnisexSymbol',
  logo_whoop: 'Various/LogoWhoop',
  referral: 'Various/Referral',
  look_better: 'Various/LookBetter',
  stop_hand: 'Various/StopHand',
  no_user_input: 'Various/NoUserInput',
  reboot: 'Various/Reboot',
  goals: 'Various/Goals',
  warranty: 'Various/Warranty',
  attention: 'Various/Attention',
  logo_whoop_circle: 'Various/LogoWhoopCircle',
  return: 'Various/Return',
  team_browse: 'Various/TeamBrowse',
  gesture_swipe_up: 'Various/GestureSwipeUp',
  share_android: 'Various/ShareAndroid',
  time: 'Various/Time',
  podcast: 'Various/Podcast',
  battery: 'Various/Battery',
  gesture_tap: 'Various/GestureTap',
  team: 'Various/Team',
  expires: 'Various/Expires',
  expired: 'Various/Expired',
  hide_privacy: 'Various/HidePrivacy',
  cancel_membership: 'Various/CancelMembership',
  tutorials: 'Various/Tutorials',
  pregnancy: 'Various/Pregnancy',
  notification: 'Various/Notification',
  unlocked: 'Various/Unlocked',
  article: 'Various/Article',
  feedback: 'Various/Feedback',
  thumb_up: 'Various/ThumbUp',
  add_team_member: 'Various/AddTeamMember',
  firmware: 'Various/Firmware',
  male_symbol: 'Various/MaleSymbol',
  female_symbol: 'Various/FemaleSymbol',
  education: 'Various/Education',
  team_invite: 'Various/TeamInvite',
  missing_data: 'Various/MissingData',
  location: 'Various/Location',
  restore: 'Various/Restore',
  menu_plus_strap: 'Various/MenuPlusStrap',
  share: 'Various/Share',
  discover_more: 'Various/DiscoverMore',
  membership: 'Various/Membership',
  data: 'Various/Data',
  diagnostdata: 'Various/Diagnostdata',
  team_join: 'Various/TeamJoin',
  perform: 'Various/Perform',
  stress_monitor_default: 'Various/StressMonitorDefault',
  locked: 'Various/Locked',
  gesture_swipe: 'Various/GestureSwipe',
  stress_monitor_low: 'Various/StressMonitorLow',
  morning_survey: 'Various/MorningSurvey',
  privacy: 'Various/Privacy',
  analytics: 'Various/Analytics',
  tiny_camera_flash: 'Tiny/TinyCameraFlash',
  tiny_triangle_down: 'Tiny/TinyTriangleDown',
  tiny_sleep: 'Tiny/TinySleep',
  tiny_play: 'Tiny/TinyPlay',
  tiny_caret_right: 'Tiny/TinyCaretRight',
  tiny_collapse: 'Tiny/TinyCollapse',
  tiny_battery_empty: 'Tiny/TinyBatteryEmpty',
  tiny_reply: 'Tiny/TinyReply',
  tiny_help: 'Tiny/TinyHelp',
  tiny_flip: 'Tiny/TinyFlip',
  tiny_battery_half_full: 'Tiny/TinyBatteryHalfFull',
  tiny_locked: 'Tiny/TinyLocked',
  tiny_information: 'Tiny/TinyInformation',
  tiny_caret_down: 'Tiny/TinyCaretDown',
  tiny_arrow_forward: 'Tiny/TinyArrowForward',
  tiny_settings: 'Tiny/TinySettings',
  tiny_strap: 'Tiny/TinyStrap',
  tiny_cross: 'Tiny/TinyCross',
  tiny_pause: 'Tiny/TinyPause',
  tiny_caret_left: 'Tiny/TinyCaretLeft',
  tiny_unlocked: 'Tiny/TinyUnlocked',
  tiny_triangle_up: 'Tiny/TinyTriangleUp',
  tiny_attention: 'Tiny/TinyAttention',
  tiny_caret_up: 'Tiny/TinyCaretUp',
  tiny_heart_rate: 'Tiny/TinyHeartRate',
  tiny_ellipse: 'Tiny/TinyEllipse',
  tiny_battery_full: 'Tiny/TinyBatteryFull',
  tiny_checkmark: 'Tiny/TinyCheckmark',
  tiny_charging: 'Tiny/TinyCharging',
  tiny_expand: 'Tiny/TinyExpand',
  tiny_no_internet: 'Tiny/TinyNoInternet',
  search: 'Actions/Search',
  crop: 'Actions/Crop',
  receive_email: 'Actions/ReceiveEmail',
  new_message: 'Actions/NewMessage',
  new_check: 'Actions/NewCheck',
  download: 'Actions/Download',
  unmute: 'Actions/Unmute',
  camera_rotate: 'Actions/CameraRotate',
  check: 'Actions/Check',
  add: 'Actions/Add',
  copy: 'Actions/Copy',
  message: 'Actions/Message',
  report: 'Actions/Report',
  send: 'Actions/Send',
  mute: 'Actions/Mute',
  trash: 'Actions/Trash',
  edit: 'Actions/Edit',
  remove: 'Actions/Remove',
  attach: 'Actions/Attach',
  filter: 'Actions/Filter',
  customize: 'Actions/Customize',
  start_activity: 'Actions/StartActivity',
  cross: 'Actions/Cross',
  add_circle: 'Actions/AddCircle',
  journal: 'Actions/Journal',
  soccer: 'Activities/Soccer',
  cross_country_skiing: 'Activities/CrossCountrySkiing',
  parkour: 'Activities/Parkour',
  operations_flying: 'Activities/OperationsFlying',
  sauna: 'Activities/Sauna',
  tennis: 'Activities/Tennis',
  spikeball: 'Activities/Spikeball',
  powerlifting: 'Activities/Powerlifting',
  diving: 'Activities/Diving',
  handball: 'Activities/Handball',
  circus_arts: 'Activities/CircusArts',
  disc_golf: 'Activities/DiscGolf',
  commuting: 'Activities/Commuting',
  badminton: 'Activities/Badminton',
  kickboxing: 'Activities/Kickboxing',
  pilates: 'Activities/Pilates',
  stairmaster: 'Activities/Stairmaster',
  lacrosse: 'Activities/Lacrosse',
  waterpolo: 'Activities/Waterpolo',
  gaming: 'Activities/Gaming',
  skiing: 'Activities/Skiing',
  mountainbiking: 'Activities/Mountainbiking',
  manual_labor: 'Activities/ManualLabor',
  barre: 'Activities/Barre',
  paintball: 'Activities/Paintball',
  cricket: 'Activities/Cricket',
  meditation: 'Activities/Meditation',
  increase_alertness: 'Activities/IncreaseAlertness',
  paddle_tennis: 'Activities/PaddleTennis',
  steam_room: 'Activities/SteamRoom',
  air_compression: 'Activities/AirCompression',
  rugby: 'Activities/Rugby',
  squash: 'Activities/Squash',
  motor_racing: 'Activities/MotorRacing',
  horsebackriding: 'Activities/Horsebackriding',
  ice_bathing: 'Activities/IceBathing',
  watching_sports: 'Activities/WatchingSports',
  box_fitness: 'Activities/BoxFitness',
  jump_roping: 'Activities/JumpRoping',
  stretching: 'Activities/Stretching',
  sailing: 'Activities/Sailing',
  functional_fitness: 'Activities/FunctionalFitness',
  work: 'Activities/Work',
  inline_skating: 'Activities/InlineSkating',
  triathlon: 'Activities/Triathlon',
  dance: 'Activities/Dance',
  australian_football: 'Activities/AustralianFootball',
  percussive_massage: 'Activities/PercussiveMassage',
  weightlifting: 'Activities/Weightlifting',
  volleyball: 'Activities/Volleyball',
  operations_tactical: 'Activities/OperationsTactical',
  climber: 'Activities/Climber',
  fencing: 'Activities/Fencing',
  ice_skating: 'Activities/IceSkating',
  hiit: 'Activities/Hiit',
  netball: 'Activities/Netball',
  jiu_jitsu: 'Activities/JiuJitsu',
  operations_medical: 'Activities/OperationsMedical',
  ultimate: 'Activities/Ultimate',
  surfing: 'Activities/Surfing',
  martialarts: 'Activities/Martialarts',
  motocross: 'Activities/Motocross',
  wheelchair_push: 'Activities/WheelchairPush',
  cycling: 'Activities/Cycling',
  pickleball: 'Activities/Pickleball',
  golf: 'Activities/Golf',
  yoga: 'Activities/Yoga',
  assault_bike: 'Activities/AssaultBike',
  softball: 'Activities/Softball',
  operations_water: 'Activities/OperationsWater',
  elliptical: 'Activities/Elliptical',
  running: 'Activities/Running',
  high_stress_work: 'Activities/HighStressWork',
  gaelic_football: 'Activities/GaelicFootball',
  rowing: 'Activities/Rowing',
  caddying: 'Activities/Caddying',
  kayaking: 'Activities/Kayaking',
  gymnastic: 'Activities/Gymnastic',
  skateboard: 'Activities/Skateboard',
  ping_pong: 'Activities/PingPong',
  rockclimbing: 'Activities/Rockclimbing',
  duathlon: 'Activities/Duathlon',
  basketball: 'Activities/Basketball',
  obstacleracing: 'Activities/Obstacleracing',
  football: 'Activities/Football',
  stage_performance: 'Activities/StagePerformance',
  spin: 'Activities/Spin',
  baseball: 'Activities/Baseball',
  paddleboard: 'Activities/Paddleboard',
  breath_work___rfb: 'Activities/BreathWorkRfb',
  table_tennis: 'Activities/TableTennis',
  coaching: 'Activities/Coaching',
  ice_hockey: 'Activities/IceHockey',
  snowboarding: 'Activities/Snowboarding',
  track_and_field: 'Activities/TrackAndField',
  boxing: 'Activities/Boxing',
  wrestling: 'Activities/Wrestling',
  swimming: 'Activities/Swimming',
  increase_relaxation: 'Activities/IncreaseRelaxation',
  other: 'Activities/Other',
  massage_therapy: 'Activities/MassageTherapy',
  walking: 'Activities/Walking',
  rollerblading: 'Activities/Rollerblading',
  hiking_rucking: 'Activities/HikingRucking',
  field_hockey: 'Activities/FieldHockey',
  hurling_camogie: 'Activities/HurlingCamogie',
  strap_unpair_remove: 'Strap/StrapUnpairRemove',
  strap: 'Strap/Strap',
  strap_vibration: 'Strap/StrapVibration',
  strap_with_cutout: 'Strap/StrapWithCutout',
  strap_add: 'Strap/StrapAdd',
  strap_settings: 'Strap/StrapSettings',
  high_performance_glasses: 'ProductHighlights/HighPerformanceGlasses',
  precious_metals: 'ProductHighlights/PreciousMetals',
  stainless_steel: 'ProductHighlights/StainlessSteel',
  machine_washable: 'ProductHighlights/MachineWashable',
  chlorine_resistant: 'ProductHighlights/ChlorineResistant',
  no_expiration: 'ProductHighlights/NoExpiration',
  bonded_seams: 'ProductHighlights/BondedSeams',
  benefits_charity: 'ProductHighlights/BenefitsCharity',
  antimicrobial: 'ProductHighlights/Antimicrobial',
  anti_glare: 'ProductHighlights/AntiGlare',
  sleep_outline: 'ProductHighlights/SleepOutline',
  no_light_leak: 'ProductHighlights/NoLightLeak',
  cell_key_pocket: 'ProductHighlights/CellKeyPocket',
  stopwatch: 'ProductHighlights/Stopwatch',
  ease_of_motion: 'ProductHighlights/EaseOfMotion',
  pocket: 'ProductHighlights/Pocket',
  microfiber_cloth: 'ProductHighlights/MicrofiberCloth',
  premium_construction: 'ProductHighlights/PremiumConstruction',
  packs: 'ProductHighlights/Packs',
  titanium: 'ProductHighlights/Titanium',
  shop_only: 'ProductHighlights/ShopOnly',
  breathable: 'ProductHighlights/Breathable',
  rhr_outline: 'ProductHighlights/RhrOutline',
  yarn: 'ProductHighlights/Yarn',
  quick_drying: 'ProductHighlights/QuickDrying',
  strap_gen4: 'ProductHighlights/StrapGen4',
  lux: 'ProductHighlights/Lux',
  collaboration: 'ProductHighlights/Collaboration',
  blue_light_blocking: 'ProductHighlights/BlueLightBlocking',
  more_rem: 'ProductHighlights/MoreRem',
  high_performance: 'ProductHighlights/HighPerformance',
  adjustable_strap: 'ProductHighlights/AdjustableStrap',
  strap_gen3: 'ProductHighlights/StrapGen3',
  three_sixty: 'ProductHighlights/ThreeSixty',
  transferable: 'ProductHighlights/Transferable',
  comfort_all_day_wear: 'ProductHighlights/ComfortAllDayWear',
  pod: 'ProductHighlights/Pod',
  reflective: 'ProductHighlights/Reflective',
  compression: 'ProductHighlights/Compression',
  recovery_score: 'ProductHighlights/RecoveryScore',
  supportive: 'ProductHighlights/Supportive',
  fast_drying: 'ProductHighlights/FastDrying',
  waterproof: 'ProductHighlights/Waterproof',
};

export type IconTypeVariants =
  | 'sign_up'
  | 'succeed_red'
  | 'refer_a_friend'
  | 'succeed_gray'
  | 'tonnage'
  | 'list'
  | 'training_adaptation'
  | 'activity_detection'
  | 'heart_rate'
  | 'activity_settings'
  | 'strain'
  | 'break'
  | 'superset'
  | 'calories'
  | 'data_catch_up'
  | 'bluetooth'
  | 'no_internet'
  | 'integrations'
  | 'phone'
  | 'charging'
  | 'cloud'
  | 'hours_of_sleep'
  | 'bedtime'
  | 'sleep'
  | 'disturbances'
  | 'waketime'
  | 'latency'
  | 'nap'
  | 'duration'
  | 'efficiency'
  | 'sleep_need'
  | 'sleep_debt'
  | 'hrv_max'
  | 'recovery'
  | 'breathwork'
  | 'nsdr'
  | 'hrv_outline'
  | 'hrv_min'
  | 'max_heart_rate'
  | 'blood_oxygen'
  | 'skin_temperature'
  | 'rhr'
  | 'menstrual_cycle'
  | 'hrv_fill'
  | 'respiratory_rate'
  | 'score_hidden'
  | 'pay_pal'
  | 'visa'
  | 'apple_pay'
  | 'google_pay'
  | 'mastercard'
  | 'amex'
  | 'diners_club'
  | 'shop_pay'
  | 'discover'
  | 'weight'
  | 'whoop_live'
  | 'locked_pa'
  | 'profile'
  | 'weekly_pa'
  | 'pause'
  | 'stop'
  | 'monthly_pa'
  | 'home'
  | 'camera'
  | 'camera_ratio'
  | 'caret_right'
  | 'sleep_schedule'
  | 'adv_settings'
  | 'coach'
  | 'arrow_left'
  | 'caret_left'
  | 'caret_up'
  | 'arrow_right'
  | 'zoom_out'
  | 'community'
  | 'undo'
  | 'caret_down'
  | 'menu'
  | 'strain_coach'
  | 'annual_pa'
  | 'play'
  | 'reply'
  | 'dot_menu'
  | 'live'
  | 'settings'
  | 'sleep_coach'
  | 'stop_activity'
  | 'zoom_in'
  | 'credit_card'
  | 'new_arrivals'
  | 'mxm_support'
  | 'shipping'
  | 'discount'
  | 'key'
  | 'gift_card'
  | 'partnership'
  | 'shopping_cart'
  | 'chat'
  | 'gift'
  | 'ownership'
  | 'membership_extend'
  | 'thumb_down_filled'
  | 'return_arrow'
  | 'leave_team'
  | 'thumb_down'
  | 'logo_pro'
  | 'gesture_pinch'
  | 'advice'
  | 'notes_edit'
  | 'export'
  | 'feel_better'
  | 'my_account'
  | 'thumb_up_filled'
  | 'drag'
  | 'help_center'
  | 'unisex_symbol'
  | 'logo_whoop'
  | 'referral'
  | 'look_better'
  | 'stop_hand'
  | 'no_user_input'
  | 'reboot'
  | 'goals'
  | 'warranty'
  | 'attention'
  | 'logo_whoop_circle'
  | 'return'
  | 'team_browse'
  | 'gesture_swipe_up'
  | 'share_android'
  | 'time'
  | 'podcast'
  | 'battery'
  | 'gesture_tap'
  | 'team'
  | 'expires'
  | 'expired'
  | 'hide_privacy'
  | 'cancel_membership'
  | 'tutorials'
  | 'pregnancy'
  | 'notification'
  | 'unlocked'
  | 'article'
  | 'feedback'
  | 'thumb_up'
  | 'add_team_member'
  | 'firmware'
  | 'male_symbol'
  | 'female_symbol'
  | 'education'
  | 'team_invite'
  | 'missing_data'
  | 'location'
  | 'restore'
  | 'menu_plus_strap'
  | 'share'
  | 'discover_more'
  | 'membership'
  | 'data'
  | 'diagnostdata'
  | 'team_join'
  | 'perform'
  | 'stress_monitor_default'
  | 'locked'
  | 'gesture_swipe'
  | 'stress_monitor_low'
  | 'morning_survey'
  | 'privacy'
  | 'tiny_camera_flash'
  | 'tiny_triangle_down'
  | 'tiny_sleep'
  | 'tiny_play'
  | 'tiny_caret_right'
  | 'tiny_collapse'
  | 'tiny_battery_empty'
  | 'tiny_reply'
  | 'tiny_help'
  | 'tiny_flip'
  | 'tiny_battery_half_full'
  | 'tiny_locked'
  | 'tiny_information'
  | 'tiny_caret_down'
  | 'tiny_arrow_forward'
  | 'tiny_settings'
  | 'tiny_strap'
  | 'tiny_cross'
  | 'tiny_pause'
  | 'tiny_caret_left'
  | 'tiny_unlocked'
  | 'tiny_triangle_up'
  | 'tiny_attention'
  | 'tiny_caret_up'
  | 'tiny_heart_rate'
  | 'tiny_ellipse'
  | 'tiny_battery_full'
  | 'tiny_checkmark'
  | 'tiny_charging'
  | 'tiny_expand'
  | 'tiny_no_internet'
  | 'search'
  | 'crop'
  | 'receive_email'
  | 'new_message'
  | 'new_check'
  | 'download'
  | 'unmute'
  | 'camera_rotate'
  | 'check'
  | 'add'
  | 'copy'
  | 'message'
  | 'report'
  | 'send'
  | 'mute'
  | 'trash'
  | 'edit'
  | 'remove'
  | 'attach'
  | 'filter'
  | 'customize'
  | 'start_activity'
  | 'cross'
  | 'add_circle'
  | 'journal'
  | 'soccer'
  | 'cross_country_skiing'
  | 'parkour'
  | 'operations_flying'
  | 'sauna'
  | 'tennis'
  | 'spikeball'
  | 'powerlifting'
  | 'diving'
  | 'handball'
  | 'circus_arts'
  | 'disc_golf'
  | 'commuting'
  | 'badminton'
  | 'kickboxing'
  | 'pilates'
  | 'stairmaster'
  | 'lacrosse'
  | 'waterpolo'
  | 'gaming'
  | 'skiing'
  | 'mountainbiking'
  | 'manual_labor'
  | 'barre'
  | 'paintball'
  | 'cricket'
  | 'meditation'
  | 'increase_alertness'
  | 'paddle_tennis'
  | 'steam_room'
  | 'air_compression'
  | 'rugby'
  | 'squash'
  | 'motor_racing'
  | 'horsebackriding'
  | 'ice_bathing'
  | 'watching_sports'
  | 'box_fitness'
  | 'jump_roping'
  | 'stretching'
  | 'sailing'
  | 'functional_fitness'
  | 'work'
  | 'inline_skating'
  | 'triathlon'
  | 'dance'
  | 'australian_football'
  | 'percussive_massage'
  | 'weightlifting'
  | 'volleyball'
  | 'operations_tactical'
  | 'climber'
  | 'fencing'
  | 'ice_skating'
  | 'hiit'
  | 'netball'
  | 'jiu_jitsu'
  | 'operations_medical'
  | 'ultimate'
  | 'surfing'
  | 'martialarts'
  | 'motocross'
  | 'wheelchair_push'
  | 'cycling'
  | 'pickleball'
  | 'golf'
  | 'yoga'
  | 'assault_bike'
  | 'softball'
  | 'operations_water'
  | 'elliptical'
  | 'running'
  | 'high_stress_work'
  | 'gaelic_football'
  | 'rowing'
  | 'caddying'
  | 'kayaking'
  | 'gymnastic'
  | 'skateboard'
  | 'ping_pong'
  | 'rockclimbing'
  | 'duathlon'
  | 'basketball'
  | 'obstacleracing'
  | 'football'
  | 'stage_performance'
  | 'spin'
  | 'baseball'
  | 'paddleboard'
  | 'breath_work___rfb'
  | 'table_tennis'
  | 'coaching'
  | 'ice_hockey'
  | 'snowboarding'
  | 'track_and_field'
  | 'boxing'
  | 'wrestling'
  | 'swimming'
  | 'increase_relaxation'
  | 'other'
  | 'massage_therapy'
  | 'walking'
  | 'rollerblading'
  | 'hiking_rucking'
  | 'field_hockey'
  | 'hurling_camogie'
  | 'strap_unpair_remove'
  | 'strap'
  | 'strap_vibration'
  | 'strap_with_cutout'
  | 'strap_add'
  | 'strap_settings'
  | 'high_performance_glasses'
  | 'precious_metals'
  | 'stainless_steel'
  | 'machine_washable'
  | 'chlorine_resistant'
  | 'no_expiration'
  | 'bonded_seams'
  | 'benefits_charity'
  | 'antimicrobial'
  | 'anti_glare'
  | 'sleep_outline'
  | 'no_light_leak'
  | 'cell_key_pocket'
  | 'stopwatch'
  | 'ease_of_motion'
  | 'pocket'
  | 'microfiber_cloth'
  | 'premium_construction'
  | 'packs'
  | 'titanium'
  | 'shop_only'
  | 'breathable'
  | 'rhr_outline'
  | 'yarn'
  | 'quick_drying'
  | 'strap_gen4'
  | 'lux'
  | 'collaboration'
  | 'blue_light_blocking'
  | 'more_rem'
  | 'high_performance'
  | 'adjustable_strap'
  | 'strap_gen3'
  | 'three_sixty'
  | 'transferable'
  | 'comfort_all_day_wear'
  | 'pod'
  | 'reflective'
  | 'compression'
  | 'recovery_score'
  | 'supportive'
  | 'fast_drying'
  | 'waterproof'
  | 'analytics';
