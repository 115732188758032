import type { Language, Region } from '../types/internationalization';
import type { CountryCode } from '../types/address';
import { getLanguagesForCountry, pickLanguage } from './languageHelpers';
import { getCountriesForRegion } from './addressHelpers';

export const DEFAULT_PATH = 'us/en';

export const getRouteForCountryAndLanguage = ({
  country,
  language,
  path = '',
}: {
  country: CountryCode;
  language: Language;
  path?: string;
}): string => {
  return `/${country.toLowerCase()}/${language}/${path}`;
};

export const getRouteForNewCountry = ({
  country,
  acceptLanguageHeader,
  path,
}: {
  country: CountryCode;
  acceptLanguageHeader: string;
  path?: string;
}): string => {
  const availableLanguages = getLanguagesForCountry(country);
  const language = pickLanguage(availableLanguages, acceptLanguageHeader);
  return getRouteForCountryAndLanguage({ country, language, path });
};

export const convertLegacyRoute = ({
  language,
  region,
  country,
  slug,
}: {
  language: Language;
  region: Region;
  country: CountryCode;
  slug: string;
}): string => {
  const availableCountries = getCountriesForRegion(region);
  const availableCountryCodes = availableCountries.map(
    (country) => country.alpha2,
  );
  if (availableCountries.length === 1) {
    return getRouteForCountryAndLanguage({
      country: availableCountryCodes[0],
      language,
      path: slug,
    });
  } else if (availableCountryCodes.includes(country)) {
    return getRouteForCountryAndLanguage({ country, language, path: slug });
  }
  return '/';
};

export const countryAndLanguageRegex = /[a-z]{2}\/[a-z]{2}(?=\/|$)/;

export const isValidPath = (path: string): boolean => {
  const [urlCountry, urlLanguage] = path.split('/');
  const language = urlLanguage as Language;
  const country = urlCountry.toUpperCase();
  const supportedLanguages = getLanguagesForCountry(country);
  return supportedLanguages.includes(language);
};

export const getCountryFromValidPath = (validPath: string) =>
  isValidPath(validPath) ? validPath.split('/')[0] : null;
