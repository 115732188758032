interface IReplaceTemplateData {
  template: string;
  replacement: string;
  variable: '{{price}}' | '{{date}}' | '{{annualPrice}}' | '{{24mPrice}}'; // {{percent}}
}

const replaceTemplateVariable = ({ template, variable, replacement }): string =>
  template.replaceAll(variable, replacement);

const replaceTemplateDiscount = ({
  template,
  replacement,
}: Omit<IReplaceTemplateData, 'variable'>): string =>
  replaceTemplateVariable({ template, variable: '{{discount}}', replacement });

const replaceTemplateAnnualPrice = ({
  template,
  replacement,
}: Omit<IReplaceTemplateData, 'variable'>): string =>
  replaceTemplateVariable({
    template,
    variable: '{{annualPrice}}',
    replacement,
  });

const replaceTemplateTwentyfourMonthPrice = ({
  template,
  replacement,
}: Omit<IReplaceTemplateData, 'variable'>): string =>
  replaceTemplateVariable({
    template,
    variable: '{{24mPrice}}',
    replacement,
  });

const replaceTemplateMembershipPrices = ({
  template,
  annualPrice,
  twentyfourMonthPrice,
}: {
  template: string;
  annualPrice?: string;
  twentyfourMonthPrice?: string;
}): string => {
  let result = template;

  if (annualPrice) {
    result = replaceTemplateAnnualPrice({
      template: result,
      replacement: annualPrice,
    });
  }

  if (twentyfourMonthPrice) {
    result = replaceTemplateTwentyfourMonthPrice({
      template: result,
      replacement: twentyfourMonthPrice,
    });
  }

  return result;
};

export {
  replaceTemplateDiscount,
  replaceTemplateMembershipPrices,
  replaceTemplateVariable,
};
