'use client';

import React, { useEffect, useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { PromoBanner } from 'ui/components/PromoBanner/PromoBanner';
import { useTranslation } from '@whoop/i18n/lang/client';
import { BANNER_THEMES } from 'ui/types/PromoCodeThemes';
import { formatDate } from '@whoop/i18n';
import {
  ANNUAL_MEMBERSHIP_KEY,
  TWENTYFOUR_MONTH_MEMBERSHIP_KEY,
} from 'ui/utils/constants/membershipConstants';
import { getFormatters } from 'ui/hooks/formatter';
import { useSiteStore } from '@/store/SiteStoreProvider';
import { useGetCurrentPage } from '@/hooks/getCurrentPage';
import { featureFlagNames } from '@/client/featureFlagClient';
import { getUTCDateWithoutOffset } from '@/lib/utils/dateHelpers';
import { replaceTemplateMembershipPrices } from '@/lib/utils/promoBannerHelpers';
import { getSiteBannerText } from './getSiteBannerText';

export const PromoBannerWrapper = (): JSX.Element | null => {
  const page = useGetCurrentPage();
  const {
    siteWidePromoContent,
    featureFlags,
    language,
    region,
    siteBannerText,
    setSiteBannerText,
    ecommCatalog,
    currency,
  } = useSiteStore(
    useShallow((state) => ({
      siteWidePromoContent: state.siteWidePromoContent,
      featureFlags: state.featureFlags,
      language: state.language,
      region: state.region,
      siteBannerText: state.siteBannerText,
      setSiteBannerText: state.setSiteBannerText,
      ecommCatalog: state.ecommCatalog,
      currency: state.currency,
    })),
  );
  const { formatDisplayPrice } = getFormatters({ currency, language, region });
  const siteBannerId = 'SITEBANNER';
  const { t } = useTranslation('joinLandingPage', language);

  const getBannerText = async () => {
    if (featureFlags[featureFlagNames.DISABLE_IL_JF] && region === 'IL') {
      const israelText = t('notShippingToIsrael');
      setSiteBannerText(israelText);
    } else if (featureFlags[featureFlagNames.SITE_BANNER]) {
      try {
        const bannerText = await getSiteBannerText(language, siteBannerId);
        setSiteBannerText(bannerText);
      } catch (_err) {
        // If getting content fails, just don't show the banner
      }
    }
  };

  useEffect(() => {
    if (!siteBannerText) {
      getBannerText();
    }
  }, [language, featureFlags]);

  const annualDiscountedPrice = useMemo(() => {
    return ecommCatalog.memberships
      .find((membership) => membership.key === ANNUAL_MEMBERSHIP_KEY)
      ?.variants.find((variant) => variant.price?.discounted)?.price
      ?.discounted;
  }, [ecommCatalog]);

  const twentyfourMonthDiscountedPrice = useMemo(() => {
    return ecommCatalog.memberships
      .find((membership) => membership.key === TWENTYFOUR_MONTH_MEMBERSHIP_KEY)
      ?.variants.find((variant) => variant.price?.discounted)?.price
      ?.discounted;
  }, [ecommCatalog]);

  const promoEndDate = siteWidePromoContent?.promoEndDate
    ? new Date(siteWidePromoContent.promoEndDate)
    : undefined;

  const absolutePromoEndDate = promoEndDate
    ? getUTCDateWithoutOffset(promoEndDate)
    : undefined;

  const isTodayAfterDate = absolutePromoEndDate
    ? new Date() > absolutePromoEndDate
    : false;

  const validUntil =
    absolutePromoEndDate && !isTodayAfterDate
      ? t('ends', {
          date: formatDate(absolutePromoEndDate, language, {
            month: 'short',
            day: 'numeric',
          }),
        })
      : undefined;

  if (page === 'Order' || page === 'Receipt') {
    return null;
  }

  // If site wide banner content, show even if partnership
  if (siteBannerText) {
    return <PromoBanner content={siteBannerText} />;
  }

  const bannerText = replaceTemplateMembershipPrices({
    template: siteWidePromoContent?.bannerText ?? '',
    annualPrice: annualDiscountedPrice
      ? formatDisplayPrice(annualDiscountedPrice.value.cent_amount)
      : undefined,
    twentyfourMonthPrice: twentyfourMonthDiscountedPrice
      ? formatDisplayPrice(twentyfourMonthDiscountedPrice.value.cent_amount)
      : undefined,
  });

  return siteWidePromoContent?.bannerText ? (
    <PromoBanner
      content={bannerText}
      tagText={validUntil}
      theme={BANNER_THEMES[siteWidePromoContent.theme ?? 'default']}
    />
  ) : null;
};
