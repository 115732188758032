import type { IconProps } from '../../../components/Icon/Icon.util';

export const ArrowLeft = ({
  title,
  width = 32,
  height = 32,
  fill = 'none',
  role = 'presentation',
  className,
}: IconProps): React.ReactNode => {
  const viewBoxSize = 32;

  return (
    <svg
      fill={fill}
      focusable='false'
      height={height}
      role={role}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      {title ? <title>{title}</title> : null}

      <path d='M2.43224 14.9223C1.85596 15.5175 1.85596 16.4824 2.43224 17.0775L13.6885 28.7022C14.0726 29.099 14.6955 29.099 15.0797 28.7022L15.8543 27.9022C16.2385 27.5055 16.2385 26.8622 15.8543 26.4654L7.22082 17.5493L29 17.5493C29.5523 17.5493 30 17.1016 30 16.5493V15.4514C30 14.8991 29.5523 14.4514 29 14.4514L7.22019 14.4514L15.8544 5.5346C16.2385 5.13783 16.2385 4.49456 15.8544 4.09779L15.0797 3.29782C14.6955 2.90105 14.0727 2.90105 13.6885 3.29782L2.43224 14.9223Z' />
    </svg>
  );
};
