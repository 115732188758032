'use client';

import { useShallow } from 'zustand/react/shallow';
import { Pagination } from 'ui/components/Pagination/Pagination';
import { useTranslation } from '@whoop/i18n/lang/client';
import { useMemo } from 'react';
import { AmplitudeEvents } from 'whoop-analytics/lib/types';
import { useSiteStore } from '@/store/SiteStoreProvider';
import { useGetCurrentPage } from '@/hooks/getCurrentPage';
import { useNavigation } from '@/hooks/navigation';
import { useAnalytics } from '@/hooks/analytics';
import { env } from 'env';
import { Header } from './Header';
import { FAMILY_MEMBERSHIP_KEY } from 'ui/utils/constants/familyConstants';
import { FAMILY, GIFT } from '@/lib/constants/memberships';

function PaginationHeader(): JSX.Element {
  const { getLocalizedPath, searchParams } = useNavigation();
  const { trackAmplitudeEvent } = useAnalytics();

  const { catalog, toggleIsCartOpen, cart, skipMemSelectFlow } = useSiteStore(
    useShallow((state) => ({
      catalog: state.ecommCatalog,
      toggleIsCartOpen: state.toggleIsCartOpen,
      cart: state.cart,
      skipMemSelectFlow: state.skipMemSelectFlow,
    })),
  );

  const currentPage = useGetCurrentPage();
  const { t } = useTranslation('joinLandingPage');

  const membershipInCart = cart.cartItems.find(
    (item) => item.productType === 'membership',
  );
  const isMembershipInCart = Boolean(membershipInCart);

  const showAccessories = catalog.accessories.length;

  const optionsItems = {
    landing: {
      label: '1',
      link: getLocalizedPath(''),
      enabled: true,
      desktopText: t('header.customize'),
    },
    membership: {
      label: '2',
      link: getLocalizedPath('membership'),
      enabled: false,
      desktopText: t('header.chooseMembership'),
    },
    family: {
      label: '2',
      link: getLocalizedPath('family'),
      enabled: true,
      desktopText: t('header.chooseMembership'),
    },
    accessories: {
      label: '3',
      link: getLocalizedPath('accessories'),
      enabled: false,
      desktopText: t('header.addAccessories'),
    },
  };

  let options = () => {
    let items = [optionsItems.landing, optionsItems.membership];

    if (currentPage === 'Family') {
      items = [optionsItems.landing, optionsItems.family];
    }

    if (showAccessories) {
      if (membershipInCart?.key === FAMILY_MEMBERSHIP_KEY) {
        items = [optionsItems.landing, optionsItems.family];
      }
      items.push(optionsItems.accessories);
    }

    if (isMembershipInCart) {
      items[1].enabled = true;
    } else if (currentPage === 'Membership' || currentPage === 'Family') {
      items[1].enabled = true;
    } else {
      items[1].enabled = false;
    }

    if (showAccessories) {
      if (isMembershipInCart) {
        items[2].enabled = true;
      } else {
        items[2].enabled = false;
      }
    }

    return items;
  };

  if (skipMemSelectFlow) {
    // we only need to check that membership param is family here for familySkipMembershipEligible
    // because if size isn't there or out of range, skipMemSelectFlow will be undefined
    const familySkipMembershipEligible =
      searchParams?.get('membership') === FAMILY;
    const skipGiftSelect = skipMemSelectFlow === GIFT;
    let chooseMembershipLink = `${env.NEXT_PUBLIC_WHOOP_URL}/membership#choose-plan`;
    if (familySkipMembershipEligible)
      chooseMembershipLink = `${env.NEXT_PUBLIC_WHOOP_URL}/family-membership`;
    if (skipGiftSelect)
      chooseMembershipLink = `${env.NEXT_PUBLIC_WHOOP_URL}/gifting/`;

    options = () => {
      const items = [
        {
          label: '1',
          link: chooseMembershipLink,
          enabled: true,
          desktopText: skipGiftSelect
            ? t('header.chooseGift')
            : t('header.chooseMembership'),
        },
        {
          label: '2',
          link: getLocalizedPath(''),
          enabled: true,
          desktopText: t('header.customize'),
        },
      ];

      if (showAccessories) {
        items.push({
          label: '3',
          link: getLocalizedPath('accessories'),
          enabled: false,
          desktopText: t('header.addAccessories'),
        });

        if (currentPage === 'Accessories') {
          items[2].enabled = true;
        } else {
          items[2].enabled = false;
        }
      }

      return items;
    };
  }

  const cartCounter = useMemo(() => {
    return cart.cartItems
      .map((product) => product.quantity)
      .reduce((prev, curr) => prev + curr, 0);
  }, [cart.cartItems]);

  const pagesWithHeader = [
    'Join',
    'Accessories',
    !skipMemSelectFlow && 'Membership',
    !skipMemSelectFlow && 'Family',
  ];

  const showCart = ['Membership', 'Accessories', 'Family'].includes(
    currentPage,
  );
  const showCurrency = currentPage === 'Join';

  const trackClick = (name: string, number: number): void => {
    trackAmplitudeEvent(AmplitudeEvents.ClickedHeader, { name, number });
  };

  return (
    <Header
      cartCounter={cartCounter}
      showCart={showCart}
      showCountrySelector={showCurrency}
      showOrderPageV2Header={currentPage === 'Order'}
      skipMemQueryParam={skipMemSelectFlow}
      toggleIsCartOpen={toggleIsCartOpen}
    >
      <div className='absolute left-[50vw] flex -translate-x-1/2 justify-center'>
        {pagesWithHeader.includes(currentPage) &&
        catalog?.memberships.length ? (
          <Pagination
            activeIndex={0}
            items={options()}
            trackHeaderClick={trackClick}
          />
        ) : null}
      </div>
    </Header>
  );
}

export default PaginationHeader;
