import React from 'react';
import clsx from 'clsx';
import type { Language } from '@whoop/i18n';
import type {
  CountrySelection,
  CountrySelectorCountry,
} from '../../../types/CountrySelectorTypes';
import { UnstyledButton } from '../../ButtonV2/Button';

interface LanguageSelectorProps {
  country: CountrySelectorCountry;
  onSelect: (countrySelection: CountrySelection) => void;
}

export function LanguageSelector({
  country,
  onSelect,
}: LanguageSelectorProps): JSX.Element {
  const handleSelection = (languageCode: Language): void => {
    onSelect({
      countryCode: country.countryCode,
      languageCode,
    });
  };

  return (
    <div className='flex w-full flex-col gap-5'>
      <h3 className='px-3 text-xl font-semibold tracking-tighter'>
        {country.displayName}
      </h3>
      <div className='grid grid-cols-2 gap-2'>
        {country.languages.map((language) => {
          return (
            <UnstyledButton
              className={clsx(
                'rounded-xl border border-transparent p-3 text-left text-base font-medium leading-5 tracking-tight transition-colors',
                'hover:border-gray-a700 hover:border',
              )}
              key={`language-selector-${language.languageCode}`}
              onClick={() => handleSelection(language.languageCode)}
              type='button'
            >
              {language.displayName}
            </UnstyledButton>
          );
        })}
      </div>
    </div>
  );
}
