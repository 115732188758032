import React, { useState } from 'react';
import type {
  CountrySelection,
  CountrySelectorRegion,
} from '../../types/CountrySelectorTypes';
import { CountrySelectorTrigger } from './CountrySelectorTrigger/CountrySelectorTrigger';
import { CountrySelectorMenu } from './CountrySelectorMenu/CountrySelectorMenu';

interface CountrySelectorProps {
  onSelect: (countrySelection: CountrySelection) => void;
  previousSelection: CountrySelection;
  regions: CountrySelectorRegion[];
  countrySelectorTitle: string;
  languageSelectorTitle: string;
  changeLanguageText: string;
  onToggle: (isOpen: boolean) => void;
  onCountrySelected?: (country: CountrySelection) => void;
}

export function CountrySelector({
  onSelect,
  previousSelection,
  regions,
  countrySelectorTitle,
  languageSelectorTitle,
  changeLanguageText,
  onToggle,
  onCountrySelected,
}: CountrySelectorProps): JSX.Element {
  const [showMenu, setShowMenu] = useState(false);

  const handleToggleCountryMenu = () => {
    onToggle(showMenu);
    setShowMenu(!showMenu);
  };

  const handleOnSelect = (countrySelection: CountrySelection): void => {
    if (onCountrySelected) {
      onCountrySelected(countrySelection);
    }
    onSelect(countrySelection);
    setShowMenu(false);
  };

  return (
    <>
      <CountrySelectorTrigger
        countryCode={previousSelection.countryCode}
        toggleCountryMenu={handleToggleCountryMenu}
      />
      <CountrySelectorMenu
        changeLanguageText={changeLanguageText}
        countrySelectorTitle={countrySelectorTitle}
        isOpen={showMenu}
        languageSelectorTitle={languageSelectorTitle}
        onSelect={handleOnSelect}
        onToggle={handleToggleCountryMenu}
        previousSelection={previousSelection}
        regions={regions}
      />
    </>
  );
}
