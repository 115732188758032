import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * Returns a string of classes. Uses clsx and tailwind-merge
 * @param inputs
 * @returns
 */
export function cltw(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs));
}

export * from './checkoutFormHelpers';
export * from './effects';
export * from './totalsHelpers';
export * from './promotionHelpers';
export * from './productHelpers';
export * from './membershipHelpers';
export * from './metadataConfig';
export * from './validation';
export * from './whoopYourWayHelpers';
export * from './shopifyClient';
export * from './urlUtils';
