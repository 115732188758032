import type { AnalyticsContext } from 'whoop-analytics';
import type { Product } from 'services/generated/commerce-service/models/Product';
import type { Variant } from 'services/generated/commerce-service/models/Variant';
import {
  getPriceValues,
  getSubscriptionType,
  getTrialDays,
  getVariantColor,
} from 'ui/utils/variantHelpers';
import type { ParsedCartItem } from 'ui/types/cartTypes';
import { getCartItemPriceValues } from '@/components/Cart/cart.utils';
import {
  ANNUAL_MEMBERSHIP_KEY,
  TRIAL_MEMBERSHIP_KEY,
  TWENTYFOUR_MONTH_MEMBERSHIP_KEY,
  MONTHLY_MEMBERSHIP_KEY,
} from 'ui/utils/constants/membershipConstants';

export const getAnalyticsAmount = (amountInCents: number): number => {
  return Number((amountInCents / 100).toFixed(2));
};

export const getNameFromVariant = (
  products: Product[],
  selectedVariant: Variant,
): string => {
  const selectedProduct = products.find((product) =>
    product.variants.some((variant) => variant.sku === selectedVariant.sku),
  );
  return selectedProduct?.name || '';
};

export const getItemProperties = (
  name: string,
  variant: Variant,
): AnalyticsContext => {
  const priceCents = getPriceValues(variant).displayPrice || 0;
  if (getVariantColor(variant) === 'Onyx') {
    return {
      sku: variant.sku,
      name: 'WHOOP 4.0',
      variant: 'Onyx',
      price: priceCents / 100,
    };
  }
  return {
    sku: variant.sku,
    name,
    price: priceCents / 100,
  };
};

export const getItemPropertiesV2 = (
  product: ParsedCartItem,
): AnalyticsContext => {
  const productDisplayPrice =
    product.discountedPrice?.value.centAmount ??
    product.originalPrice.centAmount;
  return {
    sku: product.id,
    name: product.title,
    price: getAnalyticsAmount(productDisplayPrice),
  };
};

export const getMembershipProperties = (
  membershipVariant: Variant,
  language: string,
): AnalyticsContext => {
  const name = membershipVariant.attributes.find(
    (attr) => attr.name === 'line-item-description',
  )?.value;
  const priceCents = getPriceValues(membershipVariant).displayPrice || 0;
  return {
    sku: membershipVariant.sku,
    name: name[language] || '',
    price: priceCents / 100,
    membership: getTrialDays(membershipVariant),
    is_trial: getSubscriptionType(membershipVariant) === 'trial',
  };
};

export const getMembershipDays = (membership: ParsedCartItem): number => {
  switch (membership.key) {
    case TWENTYFOUR_MONTH_MEMBERSHIP_KEY:
      return 24 * 30;
    case TRIAL_MEMBERSHIP_KEY:
    case MONTHLY_MEMBERSHIP_KEY:
      return 30;
    case ANNUAL_MEMBERSHIP_KEY:
      return 12 * 30;
    default:
      return 12 * 30;
  }
};

export const getAccessoryContextV2 = (cartItems: ParsedCartItem[]) => {
  const accessories = cartItems.filter(
    (product) =>
      product.productType !== 'membership' && product.productType !== 'device',
  );
  let uniqueAccessories = 0;
  let accessoryCount = 0;
  let accessoryTotal = 0;
  accessories.forEach((accessory) => {
    const { displayPrice } = getCartItemPriceValues(accessory);
    accessoryCount += accessory.quantity;
    accessoryTotal += displayPrice;
    uniqueAccessories += 1;
  });

  return {
    accessories,
    accessoryCount,
    accessoryTotal: getAnalyticsAmount(accessoryTotal),
    uniqueAccessories,
  };
};
