import { getMembershipInCart } from '@/components/Cart/cart.utils';
import type { Product } from 'services/generated/commerce-service/models/Product';
import type { Variant } from 'services/generated/commerce-service/models/Variant';
import { ParsedCartItem } from 'ui/types/cartTypes';
import {
  findProductByKey,
  findVariantBySku,
  getFamilySizeFromVariant,
} from 'ui/utils/variantHelpers';
import {
  ANNUAL_MEMBERSHIP_KEY,
  TWENTYFOUR_MONTH_MEMBERSHIP_KEY,
} from 'ui/utils/constants/membershipConstants';
import {
  FAMILY_MEMBERSHIP_KEY,
  MAX_FAMILY_SIZE,
  MIN_FAMILY_SIZE,
} from 'ui/utils/constants/familyConstants';
import { FAMILY, GIFT } from '@/lib/constants/memberships';
import { Currency } from '@whoop/i18n';

export const tabVariantMapping = {
  1: { key: ANNUAL_MEMBERSHIP_KEY, variant: 0 },
  2: { key: FAMILY_MEMBERSHIP_KEY, variant: 0 },
  3: { key: FAMILY_MEMBERSHIP_KEY, variant: 1 },
  4: { key: FAMILY_MEMBERSHIP_KEY, variant: 2 },
  5: { key: FAMILY_MEMBERSHIP_KEY, variant: 3 },
  6: { key: FAMILY_MEMBERSHIP_KEY, variant: 4 },
};

export const getFamilyMembershipVariant = (
  tabNumber: string,
  allMemberships: Product[],
): Variant | undefined => {
  const tabChosen = tabVariantMapping[tabNumber];
  if (tabChosen) {
    const selectedProduct = findProductByKey(allMemberships, tabChosen.key);
    return selectedProduct?.variants[tabChosen.variant];
  }
};

export const getPerPersonPrice = (
  variant?: Variant,
  discounted: boolean = true,
): number | null => {
  if (!variant) return null;
  const price =
    discounted && variant.price?.discounted
      ? variant.price.discounted.value.cent_amount
      : variant.price?.value.cent_amount;
  const familySize = getFamilySizeFromVariant(variant) || 1;
  return price ? price / familySize : null;
};

export const getLowestPerPersonFamilyPrice = (variants: Variant[]): Variant => {
  // have to use the spread operator to avoid mutating the original array (sort is destructive)
  const sortedFamilyMemberships = [...variants]
    .filter((variant) => variant.sku.includes('Family'))
    .sort((a, b) => {
      const priceA = getPerPersonPrice(a);
      const priceB = getPerPersonPrice(b);
      return priceA && priceB ? priceA - priceB : 0;
    });
  return sortedFamilyMemberships[0];
};

export const getHasFamilyMemberships = (
  memberships: Product[] | ParsedCartItem[],
): boolean => {
  const familyMembershipProduct = memberships.find(
    (p) => p.key === FAMILY_MEMBERSHIP_KEY,
  );
  return Boolean(familyMembershipProduct);
};

export const getFamilyCTADisplayData = ({
  memberships,
  isRafGiftFlow,
  isCr7Flow,
  skipMemSelection,
}: {
  memberships: Product[];
  isRafGiftFlow?: boolean;
  isCr7Flow?: boolean;
  skipMemSelection?: string;
}): {
  familyDisplayPrice: number | null;
  displayFamilyMembershipCTA: boolean;
} => {
  const familyMembershipProduct = findProductByKey(
    memberships,
    FAMILY_MEMBERSHIP_KEY,
  );
  const familyMembershipVariants = familyMembershipProduct?.variants || null;
  if (!familyMembershipVariants)
    return { familyDisplayPrice: 0, displayFamilyMembershipCTA: false };
  const lowestPriceFamilyVariant = getLowestPerPersonFamilyPrice(
    familyMembershipVariants,
  );
  const familyDisplayPrice = getPerPersonPrice(lowestPriceFamilyVariant);
  const displayFamilyMembershipCTA = Boolean(
    familyDisplayPrice &&
      !isRafGiftFlow &&
      !isCr7Flow &&
      skipMemSelection !== FAMILY &&
      skipMemSelection !== GIFT,
  );
  return { familyDisplayPrice, displayFamilyMembershipCTA };
};

export const getPriceToAddOneFamilyMember = ({
  displayMembershipProduct,
  annualMembershipProduct,
}: {
  displayMembershipProduct?: Product;
  annualMembershipProduct?: Product;
}): number => {
  if (
    displayMembershipProduct?.key !== FAMILY_MEMBERSHIP_KEY ||
    !annualMembershipProduct
  )
    return 0;
  const familySize2Variant = displayMembershipProduct.variants.find(
    (variant) =>
      variant.attributes?.find(
        (attribute) =>
          attribute.name === 'family-size' && attribute.value === 2,
      ),
  );
  const familyPrice =
    familySize2Variant?.price?.discounted?.value.cent_amount ??
    familySize2Variant?.price?.value.cent_amount ??
    0;
  const annualVariant =
    annualMembershipProduct.variants.find(
      (variant) => variant.price?.discounted,
    ) ?? annualMembershipProduct.variants[0];
  const annualPrice =
    annualVariant?.price?.discounted?.value.cent_amount ??
    annualVariant?.price?.value.cent_amount ??
    0;
  const priceToAddOneFamilyMember =
    familyPrice && annualPrice ? familyPrice - annualPrice : 0;
  return priceToAddOneFamilyMember;
};

export const getInitialTabSelected = (
  cartProducts: ParsedCartItem[],
  membershipProducts: Product[],
): string => {
  const membership = getMembershipInCart(cartProducts);
  const prepaidMemberships = [
    ANNUAL_MEMBERSHIP_KEY,
    TWENTYFOUR_MONTH_MEMBERSHIP_KEY,
  ];
  if (membership) {
    const membershipKey = membership?.key;
    if (membershipKey === FAMILY_MEMBERSHIP_KEY) {
      const variantInCart = findVariantBySku(
        membershipProducts,
        membership.sku,
      );
      if (variantInCart) {
        const familySize = getFamilySizeFromVariant(variantInCart);
        if (familySize) {
          return familySize.toString();
        }
      }
    }
    if (prepaidMemberships.includes(membershipKey)) {
      if (membership.quantity === 1) {
        return MIN_FAMILY_SIZE.toString();
      } else if (
        membership.quantity >= MIN_FAMILY_SIZE &&
        membership.quantity <= MAX_FAMILY_SIZE
      ) {
        return membership.quantity.toString();
      } else if (membership.quantity > MAX_FAMILY_SIZE) {
        return MAX_FAMILY_SIZE.toString();
      }
    }
  }
  return MIN_FAMILY_SIZE.toString();
};

export const getPercentFamilySavings = ({
  annualMembershipProduct,
  selectedMembershipProduct,
  selectedVariantNum,
}: {
  annualMembershipProduct: Product;
  selectedMembershipProduct?: Product;
  selectedVariantNum: number;
}): number => {
  const annualPerPersonPrice =
    annualMembershipProduct.variants[0].price?.value.cent_amount;
  const selectedProductVariant =
    selectedMembershipProduct?.variants[selectedVariantNum];
  const selectedPerPersonPrice = getPerPersonPrice(selectedProductVariant);
  if (
    !selectedMembershipProduct ||
    !annualPerPersonPrice ||
    !selectedPerPersonPrice
  )
    return 0;
  return Math.round(
    ((annualPerPersonPrice - selectedPerPersonPrice) * 100) /
      annualPerPersonPrice,
  );
};

export const getFamilyCardDisplayData = ({
  annualMembershipProduct,
  selectedMembershipProduct,
  selectedVariantNum,
  formatDisplayPrice,
  currency,
}: {
  annualMembershipProduct: Product;
  selectedMembershipProduct: Product;
  selectedVariantNum: number;
  formatDisplayPrice: (price: number) => string;
  currency: Currency;
}): {
  formattedYearlyPriceBasedOnAnnual: string;
  formattedYearlyPriceBasedOnFamily: string;
  formattedSavings: string;
  formattedPrice: string;
  showPerYearText: boolean;
} => {
  const annualMembershipVariant =
    annualMembershipProduct.variants.find(
      (variant) => variant.price?.discounted,
    ) ?? annualMembershipProduct.variants[0];
  const baseAnnualPrice = annualMembershipVariant.price?.value.cent_amount || 0;
  const annualPrice =
    (annualMembershipVariant.price?.discounted?.value.cent_amount ??
      annualMembershipVariant.price?.value.cent_amount) ||
    0;
  const selectedMembershipVariant =
    selectedMembershipProduct.variants[selectedVariantNum];

  const showPerYearText = !Boolean(selectedMembershipVariant.price?.discounted);

  const isAnnualMembershipProduct =
    selectedMembershipProduct.key === ANNUAL_MEMBERSHIP_KEY;

  let displayPrice, formattedPrice, familySize;

  if (isAnnualMembershipProduct) {
    displayPrice = annualPrice;
    formattedPrice = formatDisplayPrice(displayPrice || 0);
    familySize = 1;
  } else {
    displayPrice = getPerPersonPrice(selectedMembershipVariant);
    formattedPrice = formatDisplayPrice(displayPrice || 0);
    familySize = getFamilySizeFromVariant(selectedMembershipVariant) || 0;
  }

  let formattedYearlyPriceBasedOnAnnual = '';
  let formattedYearlyPriceBasedOnFamily = '';
  let formattedSavings = '';

  if (familySize && displayPrice) {
    // cost per year for familySize number of members paying the regular 12 month membership price
    const yearlyPricePasedOnAnnual = baseAnnualPrice * familySize;
    formattedYearlyPriceBasedOnAnnual = formatDisplayPrice(
      yearlyPricePasedOnAnnual,
    );
    // cost per year for familySize number of members paying the family membership price
    const yearlyPriceBasedOnFamily = displayPrice * familySize;
    formattedYearlyPriceBasedOnFamily = formatDisplayPrice(
      yearlyPriceBasedOnFamily,
    );
    // savings per year
    formattedSavings = formatDisplayPrice(
      yearlyPricePasedOnAnnual - yearlyPriceBasedOnFamily,
    );
  }
  return {
    formattedYearlyPriceBasedOnAnnual,
    formattedYearlyPriceBasedOnFamily,
    formattedSavings,
    formattedPrice,
    showPerYearText,
  };
};
