import type { Language } from '@whoop/i18n';
import type { DiscountedPrice } from 'services/generated/commerce-service/models/DiscountedPrice';
import type { Product } from 'services/generated/commerce-service/models/Product';
import type { Variant } from 'services/generated/commerce-service/models/Variant';
import { WYW_SKU } from './constants/productConstants';
import { Gender } from '../components/Accessories/SizingGuide/SizingGuide';

export const getSwatchImage = (variant: Variant): string => {
  return variant.images.find((img) => img.label === 'swatch')?.url ?? '';
};

export const getFirstImageForVariant = (variant: Variant): string => {
  return variant?.images?.find((img) => img.label !== 'swatch')?.url ?? '';
};

export const getVariantColor = (variant: Variant): string | null => {
  return (
    variant.attributes.find((att) => att.name === 'color')?.value?.toString() ??
    null
  );
};

export const getDisplayPriceFromAttributes = (
  variant: Variant,
  language: Language,
): string | undefined => {
  return variant.attributes.find((att) => att.name == 'display-price')?.value[
    language
  ];
};

interface PriceValuesResponse {
  displayPrice: number | undefined;
  originalPrice: number | undefined;
  isDiscounted: boolean;
  percentSavings: number | undefined;
}

export const getPriceValues = (variant: Variant): PriceValuesResponse => {
  const isDiscounted = Boolean(variant.price?.discounted);
  const originalPrice = variant.price?.value.cent_amount;
  let displayPrice: number | undefined;
  let percentSavings: number | undefined;
  if (isDiscounted) {
    const discountedPrice = variant.price?.discounted;
    displayPrice = discountedPrice?.value.cent_amount || undefined;
    percentSavings = discountedPrice?.discount.permyriad
      ? Math.floor(discountedPrice?.discount.permyriad / 100)
      : undefined;
  } else {
    displayPrice = originalPrice;
  }
  return {
    displayPrice,
    originalPrice,
    isDiscounted,
    percentSavings,
  };
};

export const sumPriceValues = (variants: Variant[]): number => {
  return variants.reduce(
    (sum, variant) => sum + (variant.price?.value?.cent_amount ?? 0),
    0,
  );
};

/* Returns new variant with given cent_amount if price already exists.
If discounted, recalculates the discount using the original percentage. */
export const changePriceCentAmount = (
  variant: Variant,
  centAmount: number,
): Variant => {
  if (!variant.price) return variant;

  const discountType = variant.price.discounted?.discount.discount_type;
  let discounted: DiscountedPrice | null = null;

  if (variant.price.discounted) {
    const discountPercentage =
      variant.price.discounted?.discount.permyriad ?? 0;
    const discountedPrice =
      discountType === 'absolute'
        ? variant.price.discounted.value.cent_amount
        : centAmount - Math.floor(centAmount * (discountPercentage / 10000));

    discounted = {
      ...variant.price.discounted,
      value: {
        ...variant.price.discounted.value,
        cent_amount: discountedPrice,
      },
    };
  }

  return {
    ...variant,
    price: {
      ...variant.price,
      value: {
        ...variant.price.value,
        cent_amount: centAmount,
      },
      discounted,
    },
  };
};

export const getAllNonSwatchImages = (variant: Variant): string[] => {
  return variant.images
    .filter((img) => img.label !== 'swatch')
    .map((img) => img.url);
};

export const getCartItemDisplayPhoto = (
  variant: Variant,
): string | undefined => {
  return variant.images.find((image) => image.label === 'cart-item-display')
    ?.url;
};

export const getWYWBackPhoto = (variant: Variant): string | undefined => {
  return variant.images.find((image) => image.label === 'back')?.url;
};

export const getSizeLabel = (variant: Variant): string => {
  return (
    variant.attributes.find((att) => att.name === 'size')?.value?.label ?? ''
  );
};

export const getInseamLabel = (variant: Variant): string => {
  return (
    variant.attributes.find((att) => att.name === 'inseam')?.value?.label ?? ''
  );
};

export const getVariantTitle = (variant: Variant): string => {
  const color = getVariantColor(variant);
  const size = getSizeLabel(variant);
  const inseam = getInseamLabel(variant);
  return [color, size, inseam].filter(Boolean).join(' / ');
};

export const getSizeGuideValue = (variant: Variant): string => {
  return (
    variant.attributes.find((att) => att.name === 'size-guide-key')?.value ?? ''
  );
};

export const getGenderValue = (variant: Variant): Gender => {
  return variant.attributes.find((att) => att.name === 'gender')?.value ?? '';
};

export const hasSizeAttributes = (variant: Variant): boolean => {
  return variant.attributes.some((att) => att.name === 'size');
};

export const hasInseamAttributes = (variant: Variant): boolean => {
  return variant.attributes.some((att) => att.name === 'inseam');
};

export const hasSizeGuideAttribute = (variant: Variant): boolean => {
  return variant.attributes.some((att) => att.name === 'size-guide-key');
};

export const getSubscriptionType = (variant: Variant): string => {
  return (
    variant.attributes.find((att) => att.name === 'subscriptionType')?.value
      .key ?? ''
  );
};

export const getTrialDays = (variant: Variant): number => {
  return (
    variant.attributes.find((attr) => attr.name === 'trialDays')?.value ?? 0
  );
};

const getSizeKey = (variant: Variant) =>
  variant.attributes.find((att) => att.name === 'size')?.value?.key;
const getInseamKey = (variant: Variant) =>
  variant.attributes.find((att) => att.name === 'inseam')?.value?.key;

export const getMaterialKey = (variant: Variant) =>
  variant.attributes.find((att) => att.name === 'material')?.value?.key;

export const getMaterialLabel = (variant: Variant) =>
  variant.attributes.find((att) => att.name === 'material')?.value?.label;

export const getAttributeObject = (variant: Variant, name: string) =>
  variant.attributes.find((att) => att.name === name)?.value;

export const getAccordionEntries = (variant: Variant): string[] =>
  variant?.attributes?.find((att) => att.name === 'accordion-entries')?.value ??
  [];

export const getProductDetailEntries = (variant: Variant): string[] =>
  variant?.attributes?.find((att) => att.name === 'product-detail-entries')
    ?.value ?? [];

const SIZE_ORDER = [
  'x-x-small',
  'x-small',
  'small',
  'medium',
  'large',
  'x-large',
  'x-x-large',
  'x-x-x-large',
];
const INSEAM_ORDER = ['three', 'five', 'seven'];
const BAND_ORDER = [
  'superknit',
  'sportflex',
  'superknit-luxe',
  'cloudknit',
  'hydroknit',
  'whoop-your-way',
];

export const sizeVariantSorter = (
  variant1: Variant,
  variant2: Variant,
): number => {
  const firstKey = getSizeKey(variant1);
  const secondKey = getSizeKey(variant2);
  return (
    SIZE_ORDER.indexOf(firstKey.toLowerCase()) -
    SIZE_ORDER.indexOf(secondKey.toLowerCase())
  );
};

export const inseamVariantSorter = (
  variant1: Variant,
  variant2: Variant,
): number => {
  const firstKey = getInseamKey(variant1);
  const secondKey = getInseamKey(variant2);
  return (
    INSEAM_ORDER.indexOf(firstKey.toLowerCase()) -
    INSEAM_ORDER.indexOf(secondKey.toLowerCase())
  );
};

export const bandMaterialSorter = (
  variant1: Variant,
  variant2: Variant,
): number => {
  const firstKey = getMaterialKey(variant1);
  const secondKey = getMaterialKey(variant2);
  return (
    BAND_ORDER.indexOf(firstKey.toLowerCase()) -
    BAND_ORDER.indexOf(secondKey.toLowerCase())
  );
};

export const getParentProductFromProductList = (
  selectedVariant: Variant,
  productList: Product[],
): Product | null => {
  return (
    productList.find((product) =>
      product.variants.find((variant) => variant.sku === selectedVariant.sku),
    ) ?? null
  );
};

export const findVariantBySku = (
  memberships: Product[],
  sku: string,
): Variant | undefined => {
  return memberships
    .flatMap((product) => product.variants)
    .find((variant) => variant.sku === sku);
};

export const getFamilySizeFromVariant = (
  variant: Variant,
): number | undefined => {
  if (!variant.sku?.includes('Family')) return undefined;
  const sizeAttribute = variant.attributes?.find(
    (attribute) => attribute.name === 'family-size',
  );
  return sizeAttribute?.value;
};

export const findProductByKey = (
  products: Product[],
  key: string,
): Product | undefined => {
  return products.find((product) => product.key === key);
};

export const isWywVariant = (variant: Variant | undefined) => {
  return variant?.sku === WYW_SKU;
};

export const isOnyxSuperknitBand = (variant: Variant | undefined) => {
  return variant?.sku === '955-01-000-000008';
};

export type SelectedVariantMap = Record<string, Variant>;
