import type { CountryCode } from '../types/address';
import { SUPPORTED_COUNTRIES } from '../types/address';
import {
  ALL_LANGUAGES,
  DEFAULT_CURRENCY,
  DEFAULT_REGION,
  DEFAULT_LANGUAGE,
  ALL_REGIONS,
  DEFAULT_BILLING_REGION,
} from '../types/internationalization';
import type {
  BillingRegion,
  Currency,
  JFCurrency,
  Region,
  Language,
} from '../types/internationalization';

// Defined at top level to avoid re-creating the regex on every request
// Test regex here: https://regex101.com/r/LFNEnk/1
const validLocaleRegex = /[a-z]{2}-[a-zA-Z]{2}(?=\/|$)/;

const supportedLocales = [
  'de-EU',
  'en-AE',
  'en-AU',
  'en-BH',
  'en-CA',
  'en-EU',
  'en-HK',
  'en-IL',
  'en-IN',
  'en-JP',
  'en-KR',
  'en-KW',
  'en-MX',
  'en-NZ',
  'en-SA',
  'en-SG',
  'en-TW',
  'en-UK',
  'en-US',
  'en-ZA',
  'es-EU',
  'es-MX',
  'es-US',
  'fr-CA',
  'fr-EU',
  'it-EU',
  'pt-EU',
];

export const isValidLanguage = (lang: string | undefined): boolean => {
  const allLangs = [...ALL_LANGUAGES] as string[];
  return lang !== undefined && allLangs.includes(lang);
};

export const isValidLocale = (locale: string | undefined): boolean => {
  return (
    locale !== undefined &&
    supportedLocales.some(
      (supportedLocale) =>
        supportedLocale.toLowerCase() === locale.toLowerCase(),
    )
  );
};

/**
 * if pt, or pt-* are present in the user's preferred languages above a valid lang,
 * we don't want to serve pt for any pt dialect (including pt-PT). See tests for examples.
 */
export const generateFilteredValidLangs = (
  preferredDialectList: readonly string[],
): Language[] => {
  let filterPT = false;
  const FILTERED_VALID_LANGUAGES: Language[] = [];
  for (const preferredDialect of preferredDialectList) {
    const language = preferredDialect.slice(0, 2) as Language;
    // if this is a pt dialect other than pt-PT, flip on filterPT to filter out pt later in the list
    if (
      preferredDialect.includes('pt') &&
      !preferredDialect.includes('pt-PT')
    ) {
      filterPT = true;
      continue;
    }
    // if a pt dialect other than pt-PT is higher than pt-PT, filter out all pt dialects
    else if (preferredDialect.includes('pt-PT') && filterPT) {
      continue;
    }
    // if neither of those, but is a valid lang (this includes pt-PT if it appears above pt-BR)
    // add to FILTERED_VALID_LANGUAGES if not already there
    else if (
      isValidLanguage(language) &&
      !FILTERED_VALID_LANGUAGES.includes(language)
    ) {
      FILTERED_VALID_LANGUAGES.push(language);
    }
    // if it's not valid, then filter out
    continue;
  }

  return FILTERED_VALID_LANGUAGES;
};

const getPreferredDialectList = (primaryHeaderDialectsList) => {
  return primaryHeaderDialectsList.map((dialectObj) => {
    const lang = dialectObj.code;
    const dialect = dialectObj.region;
    return dialect ? `${lang}-${dialect}` : lang;
  });
};

export const getFirstValidHeaderLangFromParserObjs = (
  primaryHeaderDialectsList,
): Language => {
  // find the first valid lang if there is one, otherwise default to en
  const preferredDialectList = getPreferredDialectList(
    primaryHeaderDialectsList,
  );
  const validLangList = generateFilteredValidLangs(preferredDialectList);
  return validLangList[0] || DEFAULT_LANGUAGE;
};

export const getLocaleFromSlug = (slug: string): string | undefined => {
  const regexResult = validLocaleRegex.exec(slug);
  if (regexResult && isValidLocale(regexResult[0])) {
    const [language, region] = regexResult[0].split('-');
    return `${language}-${region.toUpperCase()}`;
  }
};

export const sanitizePathname = (pathname: string): string => {
  const toRemove = getLocaleFromSlug(pathname);
  const withSlashesRemoved = pathname.replace(/^\/+/, '');
  return withSlashesRemoved.replace(`${toRemove}`, '');
};

/* eslint-disable id-length */
export const getCurrencyFromRegion = (region: Region): JFCurrency => {
  const currencyMap: Partial<Record<Region, JFCurrency | undefined>> = {
    AE: 'aed',
    AU: 'aud',
    CA: 'cad',
    EU: 'eur',
    NZ: 'nzd',
    SA: 'sar',
    UK: 'gbp',
    US: 'usd',
  };
  return currencyMap[region] ?? (DEFAULT_CURRENCY as JFCurrency);
};

export const getRegionFromCheckoutRegion = (
  checkoutOrigin: BillingRegion,
): Region => {
  const checkoutRegionToRegionMap: { [K in BillingRegion]: Region } = {
    australia: 'AU',
    ca: 'CA',
    domestic: 'US',
    international: 'EU',
    nz: 'NZ',
    sa: 'SA',
    uae: 'AE',
    uk: 'UK',
    qa: 'QA',
    in: 'IN',
  };
  return checkoutRegionToRegionMap[checkoutOrigin];
};

export const getCheckoutRegionFromRegion = (region: Region): BillingRegion => {
  const regionToCheckoutRegionMap: { [K in Region]: BillingRegion } = {
    AU: 'australia',
    CA: 'ca',
    US: 'domestic',
    EU: 'international',
    NZ: 'nz',
    SA: 'sa',
    AE: 'uae',
    UK: 'uk',
    QA: 'qa',
    IN: 'domestic',
    // ROW regions
    BH: 'domestic',
    HK: 'domestic',
    IL: 'domestic',
    JP: 'domestic',
    KR: 'domestic',
    KW: 'domestic',
    SG: 'domestic',
    TW: 'domestic',
    ZA: 'domestic',
    MX: 'domestic',
    BR: 'domestic',
  };
  return regionToCheckoutRegionMap[region];
};

// Not used in JF
export const getCurrencyForBillingRegion = (
  billingRegion: BillingRegion,
): Currency => {
  const currencyMap: Record<BillingRegion, Currency> = {
    australia: 'aud',
    ca: 'cad',
    domestic: 'usd',
    international: 'eur',
    nz: 'nzd',
    sa: 'sar',
    uae: 'aed',
    uk: 'gbp',
    qa: 'qar',
    in: 'inr',
  };
  return currencyMap[billingRegion];
};

/**
 * Takes in any country code and returns the region the country is grouped under
 * @param countryCode alpha2 country code
 * @returns string representing the region the country is grouped under, defaults to US if country is not part of an existing group
 */
export const getRegionFromRequest = (countryCode: CountryCode): Region => {
  return (
    SUPPORTED_COUNTRIES.find((country) => country.alpha2 === countryCode)
      ?.region ?? DEFAULT_REGION
  );
};

export const getBillingRegionFromRequest = (
  countryCode: CountryCode,
): BillingRegion => {
  if (countryCode === 'QA') {
    return 'qa';
  }
  return (
    SUPPORTED_COUNTRIES.find((country) => country.alpha2 === countryCode)
      ?.billingRegion ?? DEFAULT_BILLING_REGION
  );
};

export const isAllowedRegion = (region: string | undefined): boolean => {
  return (
    !region ||
    ALL_REGIONS.some((allowedRegion) =>
      region.toLowerCase().includes(allowedRegion.toLowerCase()),
    )
  );
};

// Function to determine if country should fire identification_required prop
// Should only fire if address.identification_number also exists
export const countryRequiresIDTracking = (countryCode = ''): boolean => {
  return countryCode === 'SA';
};

// Function to determine if region has an address form disclaimer to display, add in "addressFormDisclaimer" of addressForm
export const countryRequiresAddressFormDisclaimer = (
  country = '',
  orderTotalsRequireAddressID = true,
): boolean => {
  return country === 'SA' && orderTotalsRequireAddressID;
};

// Function to determine if renewal language should be shown to the user based on region
export const shouldExcludeRenewalCopy = (region: Region): boolean => {
  return region === 'IL';
};

export const isRestOfWorld = (currency: Currency, region: Region): boolean => {
  return currency === 'usd' && region !== 'US';
};

export const shouldShowAdditionalReceiptInfo = (
  currency: Currency,
  region: Region,
): boolean => {
  return (
    isRestOfWorld(currency, region) || currency === 'aed' || currency === 'sar'
  );
};

export const getRegionalBatteryLimit = (region: Region): number | undefined => {
  const batteryLimit: Record<string, number> = {
    IL: 5,
  };
  return batteryLimit[region];
};
