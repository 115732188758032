import type {
  Currency,
  Language,
  Region,
  PriceFormatOptions,
} from '@whoop/i18n';
import {
  formatDisplayPrice as formatDisplayPriceHelper,
  formatPrice as formatPriceHelper,
} from '../utils/formatHelpers';

interface IGetFormatters {
  currency: Currency;
  language: Language;
  region: Region;
}

interface IGetFormattersReturn {
  formatDisplayPrice: (
    price: number | undefined,
    formatOptions?: PriceFormatOptions,
  ) => string;
  formatPrice: (
    price: number | undefined,
    formatOptions?: PriceFormatOptions,
  ) => string;
}

export const getFormatters = ({
  currency,
  language,
  region,
}: IGetFormatters): IGetFormattersReturn => {
  const formatDisplayPrice = (
    price: number | undefined,
    formatOptions?: PriceFormatOptions,
  ): string => {
    if (price === undefined) {
      return '';
    }
    return formatDisplayPriceHelper(
      price,
      { currency, language, region },
      formatOptions,
    );
  };

  const formatPrice = (
    price: number | undefined,
    formatOptions?: PriceFormatOptions,
  ): string => {
    if (price === undefined) {
      return '';
    }
    return formatPriceHelper(
      price,
      { currency, language, region },
      formatOptions,
    );
  };

  return {
    formatDisplayPrice,
    formatPrice,
  };
};
